import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";

Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

Vue.filter("humanDate", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY");
});

Vue.filter("humanDateTime", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY\u002C HH:mm");
});

Vue.filter("robotDate", (date) => {
  return moment(date);
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.path) {
    if (entity.path.search(/(http:|https:)/) !== -1) {
      return entity.path;
    } else {
      return api + entity.path;
    }
  }
  return "/static/images/no-image.png";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.alt) {
    return entity.alt;
  }
  return "";
});

Vue.filter("item_img", (entity) => {
  if (entity.head_img) {
    return entity.head_img;
  }
  if (entity.images && entity.images.length) {
    return entity.images[0];
  }
  if (entity.parent && entity.parent.head_img) {
    return entity.parent.head_img;
  }
  return null;
});

Vue.filter("image_by_id", (img, state, { width, height, quality = 99 } = {}) => {
  if (img) {
    const urlParams = new URLSearchParams();
    if (width) urlParams.append("width", "" + width);
    if (height) urlParams.append("height", "" + height);
    if (quality) urlParams.append("quality", quality);
    return `${state._env.MEDIA_ENDPOINT}/action/img/${img.id}?${urlParams.toString()}`;
  }
  return "/static/images/no-image.png";
});

/**
 * Конверртация цены из долларов (с накидкой на товары) в рубли
 * entity - цена в долларах
 */
Vue.filter("price_from_dollar", (entity, state, isDelivery) => {
  if (entity && typeof window !== "undefined") {
    let rates = {};
    if (state && state.exchange_rates && state.exchange_rates.rub) {
      rates = state.exchange_rates;
    } else if (localStorage.exchange_rates) {
      rates = JSON.parse(localStorage.exchange_rates);
    }
    // берем выбранную валюту и меняем цены под нее
    let selectedCurrency = document.querySelector("[data-currency]")?.getAttribute("data-currency") || "RUB";
    let currency; // значек валюты
    let exchange; // 1 доллар в выбранной валюте
    if (selectedCurrency === "RUB") {
      exchange = isDelivery ? rates.rub_delivery : rates.rub;
      currency = "₽";
    } else if (selectedCurrency === "TRY") {
      exchange = isDelivery ? rates.lira_delivery : rates.lira;
      currency = "₺";
    } else if (selectedCurrency === "EUR") {
      exchange = isDelivery ? rates.eur_delivery : rates.eur;
      currency = "€";
    } else {
      exchange = isDelivery ? rates.usd_delivery : rates.usd;
      currency = "$";
    }
    let total = entity;
    if (exchange) {
      total = entity * exchange;
    } else {
      currency = "$";
    }
    return (
      total.toLocaleString("ru-RU", {
        maximumFractionDigits: 0,
      }) +
      " " +
      currency
    );
  }
  return 0;
});

/**
 * Конверртация рублей в доллары
 */
Vue.filter("rubles_to_dollars", (entity, state) => {
  if (entity) {
    let dollar;
    if (state.dollar) {
      dollar = state.dollar;
    } else if (typeof window !== "undefined") {
      dollar = localStorage.dollar;
    }
    if (dollar) {
      return parseInt(parseFloat(entity) / parseFloat(dollar));
    }
    return parseInt(entity);
  }
  return 0;
});

/**
 * Получение цены после скидки, если она есть
 */
Vue.filter("share_price", (price, entity) => {
  if (entity?.promo_share?.share) {
    return (parseFloat(price) * (100 - parseFloat(entity.promo_share.share.percent))) / 100;
  }
  if (entity?.join_share?.share) {
    return (parseFloat(price) * (100 - parseFloat(entity.join_share.share.percent))) / 100;
  }
  return price;
});

/**
 * Вернуть только числа из строки
 */
Vue.filter("phone", (str) => {
  return "tel:" + str.replace(/\D+/g, "");
});

/**
 * Проверка на наличие текста в EditorJs
 * если это текст от EditorJs и там пусто - false
 * undefined/null/'null' - false
 * любой другой текст - true
 */
Vue.filter("editor_has_text", (text) => {
  try {
    let parsedText = JSON.parse(text);
    if (typeof parsedText === "object") {
      return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
    }
    return !!text;
  } catch (e) {
    if (typeof text === "string") {
      return !!text;
    }
    return false;
  }
});
