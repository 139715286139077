<template>
  <router-link
    v-if="data"
    :to="{
      name: 'product',
      params: { id: data.id, link: data.link },
    }"
    class="product-search"
  >
    <ImgComponent :head_img="item_img" width="70" height="80" class="product-search__image" />
    <div class="product-search__content">
      <span class="product-search__title">
        <span v-if="data.brand && data.brand.title">
          {{ data.brand.title }}
        </span>
        <span v-if="data.brand && data.brand.title"> / </span>
        <span v-if="data.title">
          {{ data.title }}
        </span>
      </span>
      <span v-if="data.final_price" class="product-search__prices">
        <span class="product-search__price">
          {{ data.final_price | share_price(data) | price_from_dollar($store.state) }}
        </span>
        <span v-if="data.join_share && data.join_share.share" class="product-search__old-price">
          {{ data.final_price | price_from_dollar($store.state) }}
        </span>
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProductCardSearchVersionComponent",
  props: {
    data: Object,
  },
  computed: {
    item_img() {
      if (this.data.head_img) {
        return this.data.head_img;
      }
      if (this.data.images && this.data.images.length) {
        return this.data.images[0];
      }
      return null;
    },
  },
};
</script>

<style lang="stylus">
.product-search {
  display flex
  gap 16px

  &:hover &__title {
    * {
      color: var(--link);
    }
  }

  &__image {
    width 45px
    height 55px
    object-fit cover
    object-position center
    border-radius var(--small_radius)
    flex-shrink 0
  }

  &__content {
    display: flex;
    flex-direction column
    grid-gap: 4px;
  }

  &__title {
    * {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 24px;
      color: var(--body-color);
    }
  }

  &__prices {
    display flex
    align-items center
    gap 12px
  }

  &__price {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 18px;
    text-decoration-line: line-through;
    color: var(--gray-300);
  }
}
</style>
